.navbar{
    z-index: 200;
}


.cardProducto {
    width: 18rem;
    margin: 4px;
    background-color: hsl(0, 0%, 97%);
}

.cardBody {
    background-color: white;
    margin-bottom: 3px;
    text-align: center;
}

.cardBodyDark {
    background-color: hsl(0, 0, 33%);
    margin-bottom: 3px;
    text-align: center;
}

img {
    margin-top: 3px;
}


.contenedorProductos {
    margin:7px;
    align-items: center;
    justify-content: center;
}

.cardProductos {
    margin:4px;
    align-items: center;
    justify-content: center;
}

.itemDetail {
    max-width: 540px;
    margin-top: 20px;
}

.itemDetailBodyDark {
    background-color: hsl(0, 0, 33%);
    color:white;
}

.darkMode {
    background-color: black;
    color: gainsboro;
}

.cartContainer {
    margin-top: 3%;
    margin-left: 26,5%;
}

.divButtons button {
    margin:4px;
}

.cardCart {
    max-width: 640px;
}

.cardProducto {
    width: 18rem;
    margin: 4px;
    background-color: hsl(0, 0%, 97%);
}

.cardBody {
    background-color: white;
    margin-bottom: 3px;
    text-align: center;
}

img {
    margin-top: 3px;
}

.cardProductos {
    margin:4px;
    align-items: center;
    justify-content: center;
}

.itemDetail {
    max-width: 540px;
    margin-top: 20px;
}

.font{
    font-family: 'Playfair Display', serif;
    font-family: 'Poppins', sans-serif;
    }
    
    .font-color{
      color:#78c2ad;
    }
    
    .w-logo{
      width: 100%;
      
    
    }
    .a-size{
     width: 76px;
     height: 76px;
     margin-right: 1rem ;
     transition: all 0.2s ease-in-out;
    }
    
    .a-size:hover{
      transform: scale(0.8);
    }

    .cardProducto {
      width: 18rem;
      margin: 4px;
      background-color: hsl(0, 0%, 97%);
  }
  
  .cardBody {
      background-color: white;
      margin-bottom: 3px;
      text-align: center;
  }
  
  img {
      margin-top: 3px;
  }
  
  .cardProductos {
      margin:4px;
      align-items: center;
      justify-content: center;
  }
  
  .itemDetail {
      max-width: 540px;
      margin-top: 20px;
  }
  
  .font{
      font-family: 'Playfair Display', serif;
      font-family: 'Poppins', sans-serif;
      }
      
      .font-color{
        color:#78c2ad;
      }
      
      .w-logo{
        width: 100%;
        
      
      }
      .a-size{
       width: 76px;
       height: 76px;
       margin-right: 1rem ;
       transition: all 0.2s ease-in-out;
      }
      
      .a-size:hover{
        transform: scale(0.8);
      }

      .bi{
        font-size: 30px;
      }